/* General */
:root {
  --secondary: #6c757d99;
  --light: #f8f9fa;
  --dark: #212529;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  transition: all ease-in-out 250ms;
  scroll-behavior: smooth;
  scroll-padding-top: 110px;
}

#root {
  font-family: -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-color: var(--light);
  color: var(--dark);
  line-height: 1.5;
}

#root>* {
  width: 100%;
}

h3,
h4 {
  letter-spacing: 0.2rem;
}

section,
footer {
  padding: 1rem 20%;
}

hr {
  width: 150px;
  margin-left: 20%;
  border: 0;
  border-bottom: 1px solid var(--dark);
}

p {
  margin: 1rem 0;
}

/* Capçalera */
header {
  position: fixed;
  background-color: var(--primary);
  color: var(--dark);
  display: flex;
  justify-content: space-around;
  align-items: center;
  box-shadow: 0 0.25rem 0.25rem rgba(0, 0, 0, 0.25);
  padding: 1rem;
  z-index: 100;
}

header nav {
  flex: 1;
  text-align: right;
  padding: 1rem;
}

nav a {
  text-decoration: none;
  padding: 0.5rem 0;
  margin: 0 0.5rem;
  color: var(--dark);
  font-weight: 700;
  transition: none;
}

nav a:hover {
  border-bottom: 3px solid var(--dark);
}

header div,
header .button-icon {
  margin: 0 1rem;
}

.button-icon {
  cursor: pointer;
  vertical-align: middle;
  color: var(--dark);
  font-size: 1.5rem;
}

.navbar-toggler-icon {
  visibility: hidden;
}

header select {
  appearance: none;
  -webkit-appearance: none;
  background-color: transparent;
  color: var(--dark);
  border: none;
  outline: none;
  padding: 1rem;
  margin-left: 1rem;
  font-weight: 700;
  font-family: inherit;
  font-size: inherit;
  cursor: pointer;
  user-select: none;
}

header select option {
  background-color: var(--primary);
  font-weight: 700;
}

.mobile-title {
  display: none;
}

/* Contingut */
main {
  flex: 1;
  margin-top: 100px;
}

section a {
  text-decoration: none;
  color: var(--primary);
}

section a:hover {
  color: var(--dark);
}

section div {
  margin: 1.5rem 0;
}

section .projects {
  display: grid;
  gap: 2rem;
  grid-template-columns: repeat(auto-fill, minmax(370px, 1fr));
}

section .projects img {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  border: var(--dark) 2px solid;
  opacity: 0.2;
}

section .projects .project {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
}

section .projects .project div {
  transform: translateX(-100px);
}

section .projects .project:hover img {
  opacity: 1;
}

section .projects .project:hover div {
  transform: translateX(10px);
}

section .jobs {
  display: grid;
  gap: 2rem;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
}

section .job {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
  gap: 1rem;
  background-color: var(--light);
  padding: 2rem;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

section .job ul {
  list-style: none;
}

section .job li {
  text-align: left;
  margin-top: 0.5rem;
}

section .job li::before {
  content: "⌁ ";
}

section .skills {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 0.25rem;
}

section .skills span::after {
  content: " ⌁";
}

section .info {
  display: flex;
  gap: 3rem;
}

section .info svg {
  font-size: 1.5rem;
  vertical-align: top;
  margin-right: 0.5rem;
}

/* Peu */
footer {
  text-align: right;
  background-color: var(--secondary);
}

/* Responsive */
@media screen and (max-width: 1080px) {
  * {
    scroll-padding-top: 0;
  }

  header,
  section,
  footer {
    padding: 1rem 5%;
  }

  header {
    position: sticky;
    bottom: 1rem;
    align-self: flex-end;
    flex-direction: column-reverse;
    order: 3;
    width: 60px !important;
    height: 60px;
    border-radius: 50%;
    margin-right: 1rem;
  }

  header.show {
    width: 100% !important;
    height: auto;
    border-radius: 0;
    margin-right: 0;
    bottom: 0;
  }

  header div:not(.navbar-toggler-icon),
  header nav {
    display: none;
  }

  header.show div:not(.title):not(.navbar-toggler-icon) {
    display: block;
  }

  header.show nav {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }

  header.show .navbar-toggler-icon {
    position: absolute;
    bottom: 1rem;
    right: 1rem;
  }

  .navbar-toggler-icon {
    visibility: visible;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .navbar-toggler-icon svg {
    height: 30px;
    width: 30px;
  }

  .mobile-title {
    display: block;
    padding: 1rem 5%;
    background-color: var(--primary);
  }


  main {
    margin-top: 0;
  }

  section .skills {
    width: 100%;
  }

  section .info {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  hr {
    margin-left: 5%;
  }

  footer {
    text-align: left;
  }
}